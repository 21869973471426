@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400;1,500&family=Quattrocento+Sans:ital,wght@0,400;0,700;1,400&display=swap");
/* IMPORTED FONTS
* + Quattrocento Sans (Regular 400, Regular 400 Italic, Bold 700)
*
* + Mulish (XLite 200, XLite 200 Italic, Lite 300, Lite 300 Italic, Regular 400, Regular 400 Italiac, Medium 500, Medium 500 Italic)
*/

/* COLOUR PALETTE: https://coolors.co/090805-777470-888582-aba8a8-cdcbcd-F6F7FA-cfdcee-a3bedc-77a0cb-4a82b9 */

/* ==================== UNIVERSAL ==================== */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #f6f7fa;
  font-family: "Quattrocento Sans", "Mulish", Arial, Helvetica, sans-serif;
  color: #090805;
  margin: 0;
  padding: 0;
}

h3,
h4 {
  margin-block-start: 0;
  margin-block-end: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.section-heading {
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  line-height: 44px;
  margin: 0;
}

.next-line {
  display: block;
}

/* ==================== LINKS and MENU ==================== */

/* ==================== LINKS ==================== */

/* === General Links === */
a,
a i,
#menu-toggle a {
  text-decoration: none;
  transition: color 0.3s ease;
}

a:link {
  color: #3c6078;
}

a:visited {
  color: #98bddc;
}

a:hover {
  color: #cadbee;
}

a:active {
  color: #3581b8;
}

/* === Icon Links === */
a i,
a:link i,
a:visited i {
  color: #090805;
}

a:hover i {
  color: #cadbee;
}

a:active i {
  color: #aba8a8;
}

/* ==================== MENU ==================== */

#nav {
  position: fixed;
  top: 0;
}

#menu-tab {
  display: block;
  position: absolute;
  top: 24px;
  left: 24px;
  width: 34px;
  height: 22px;
  background-color: #f6f7fa;
  border-radius: 8%;
}

#menu-toggle {
  display: block;
  position: relative;
  padding-left: 24px;
  padding-top: 24px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menu-toggle a {
  color: #f6f7fa;
}

#menu-toggle a:hover {
  color: #98bddc;
}

#menu-toggle a:active {
  color: #3581b8;
}

#menu-toggle input {
  display: block;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 16px;
  left: 20px;
  cursor: pointer;
  opacity: 0; /* hides input check */
  z-index: 2; /* placed over hamburger */
  -webkit-touch-callout: none;
}

/* === Hamburger Menu Design === */
#menu-toggle span {
  display: block;
  width: 34.2px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  border-radius: 4px;
  z-index: 1;
  background: #090805;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menu-toggle span:first-child {
  transform-origin: 0% 0%;
}

#menu-toggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* === Animating hamburger to close 'X' === */

#menu-toggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #f6f7fa;
}

#menu-toggle input:checked ~ span:nth-last-child(3) /* hides the middle bar when animating to X*/ {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menu-toggle input:checked ~ span:nth-last-child(2) /* crosses the X*/ {
  transform: rotate(-45deg) translate(0px, -1px);
}

#menu {
  position: absolute;
  width: 164px;
  margin: -100px 0 0 -28px;
  padding: 0 24px;
  padding-top: 100px;
  background: #090805;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  border-radius: 0px 0px 15px 0px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}

#menu li {
  font-family: "Mulish";
  font-weight: 300;
  text-align: center;
  margin: 16px 0px;
  font-size: 20px;
  width: 100%;
}

.nav-division {
  width: 100%;
  border-radius: 50%;
}

#menu-toggle input:checked ~ ul {
  transform: none;
}

.home-container,
.about-container,
.education-container,
.projects-container,
.contact-container {
  padding: 40px 0;
}

/* ==================== HOME ==================== */

.home-container {
  text-align: center;
}

.main-title {
  font-size: 48px;
  font-weight: 700;
  margin: 0 62px;
  padding-top: 20px;
}

.profile-image-item {
  margin: 28px 62px;
}

.profile-image {
  max-width: 240px; /* 12.5em */
  max-height: 240px; /* 12.5em */
  overflow: hidden;
  border: 1px solid rgba(222, 222, 222, 0.5);
  border-radius: 40px;
}

/* ======== ANIMATED TITLE ======== */

/* ======= ANIMATED TITLE ======== */

.subtext {
  font-family: "Mulish";
  font-weight: 700;
  margin: 28px 62px;
  font-size: 20px;
  line-height: 32px;
}

.subtext p {
  margin: 0;
}

.social-icons {
  font-size: 48px;
  display: flex;
  justify-content: center;
  gap: 36px;
  margin: 0px 62px;
}

/* ==================== ABOUT ==================== */

.about-container {
  margin: 0 62px;
}

.about-content-container {
  display: flex;
  flex-flow: column wrap;
  text-align: center;
}

.bio {
  font-family: "Mulish";
  text-align: left;
  line-height: 32px;
  font-size: 16px;
}

.bio p {
  margin-bottom: 0;
}

/* ==================== EDUCATION ==================== */

.education-container {
  text-align: center;
  margin: 0px 62px;
}

.institute-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 32px;
}

.institute-card {
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 200px;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.institute-emblem {
  height: 144px;
  width: 144px;
  border-radius: 20%;
  overflow: hidden;
  border: 0.5px solid rgba(222, 222, 222, 0.5);
  object-fit: cover;
}

.institute-name {
  font-size: 16px;
  font-family: "Mulish";
  font-weight: 500;
}

/* ==================== PROJECTS ==================== */

.projects-content-container {
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
  margin: 24px 24px 0 24px;
}

/* === PROJECT ITEM CARDS === */
.project-card-item {
  border: 0.5px solid rgba(66, 62, 55, 0.5);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  text-align: center;
  width: fit-content;
}

.project-name {
  font-size: 28px;
  margin: 0 16px;
  font-family: "Mulish";
  font-weight: 500;
}

.project-preview img {
  width: 80%;
  border: 1px solid rgba(66, 62, 55, 0.5);
  border-radius: 20px;
  margin: 16px;
}

.tools-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Mulish";
  font-weight: 400;
  gap: 16px;
  margin: 16px 24px;
  font-size: 16px;
  cursor: default;
}

.tools {
  padding: 5px 10px;
  color: #090805;
  background-color: #cfdcee;
  /* border: none; */
  border: 1px solid black;
  border-radius: 8px;
}

.project-media-icon {
  display: flex;
  flex-flow: row wrap;
  gap: 80px;
  font-size: 32px;
  margin: 0 16px 16px 16px;
  justify-content: center;
}

.fa-arrow-up-right-from-square {
  padding: 0 4px;
}

/* ==================== SKILLS ==================== */

/* ==================== CONTACT ==================== */

.contact-info {
  font-family: "Mulish";
  width: fit-content;
  margin: 24px auto 0 auto;
  text-align: left;
  font-size: 16px;
}

.contact-info i {
  font-size: 24px;
}

.fa-mobile-alt {
  padding: 0 3px; /* center the mobile icon under the envelope icon*/
}

.cv-button-container {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.cv-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #090805;
  color: #f6f7fa;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cv-button:hover {
  background-color: #cadbee;
}

.cv-button:active {
  background-color: #aba8a8;
}

/* ==================== FOOTER ==================== */
#footer {
  font-family: "Mulish";
  font-weight: 200;
  text-transform: uppercase;
  font-size: 8px;
  text-align: center;
  background-color: #090805;
  color: #f6f7fa;
  padding: 16px;
  margin-top: 40px;
}

/* ==================== MEDIA QUERIES ==================== */

/* === TABLET === */

@media screen and (min-width: 769px) {
  .section-heading {
    font-size: 52px;
    line-height: 48px;
  }

  .home-container,
  .about-container,
  .education-container,
  .projects-container,
  .contact-container {
    padding: 80px 0;
  }

  /* ==================== TABLET 769px HOME ==================== */

  .profile-image {
    max-width: 340px;
    max-height: 340px;
  }

  .main-title {
    font-size: 64px;
    margin: 24px 32px 0 32px;
  }

  .subtext {
    font-size: 24px;
    line-height: 40px;
    margin: 28px 32px;
  }

  .social-icons {
    font-size: 64px;
    gap: 58px;
  }

  /* ==================== TABLET 769px ABOUT ==================== */

  .about-content-container {
    padding: 40px 140px 0 140px;
  }

  .bio {
    line-height: 36px;
    font-size: 20px;
  }

  /* ==================== TABLET 769px EDUCATION ==================== */

  .institute-container {
    padding-top: 40px;
    margin-top: 40px;
  }

  .institute-card {
    height: 280px;
    width: 280px;
  }

  .institute-emblem {
    height: 212px;
    width: 212px;
    border-radius: 25%;
  }

  .institute-name {
    font-size: 20px;
  }

  /* ==================== TABLET 769px PROJECTS ==================== */

  .projects-content-container {
    gap: 32px;
    margin: 40px 62px 0 62px;
    padding: 40px 62px 0 62px;
  }

  /* === PROJECT ITEM CARDS === */
  .project-card-item {
    border: 0.5px solid rgba(66, 62, 55, 0.5);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 80px;
  }

  .project-name {
    font-size: 36px;
  }

  .project-preview img {
    border-radius: 40px;
    margin: 32px;
  }

  .project-description,
  .tools-container {
    margin: 28px 40px;
    line-height: 36px;
    font-size: 20px;
  }

  .project-media-icon {
    gap: 160px;
    font-size: 36px;
    margin: 0 32px 32px 32px;
  }

  .fa-arrow-up-right-from-square {
    padding: 0 4.5px;
  }

  /* ==================== TABLET 769px CONTACT ==================== */

  .contact-content-container {
    margin: 40px 62px;
    padding: 40px 140px 0 140px;
  }

  .contact-info {
    font-size: 20px;
  }

  .contact-info i {
    font-size: 28px;
  }

  .fa-mobile-alt {
    padding: 0 3.5px; /* center the mobile icon under the envelope icon */
  }

  .cv-button {
    font-size: 20px;
    padding: 14px 24px;
    border-radius: 16px;
  }

  /* ==================== TABLET 769px FOOTER ==================== */
}

/* === DESKTOP === */

@media screen and (min-width: 1025px) {
  #home,
  #about,
  #education,
  #projects,
  #contact {
    max-width: 1440px;
    margin: auto;
  }

  /* ==================== DESKTOP 1025px MENU ==================== */

  #menu {
    width: 288px;
    padding: 100px 64px 0 64px;
  }

  #menu li {
    margin: 28px 0px;
    font-size: 28px;
  }

  /* ==================== DESKTOP 1025px HOME ==================== */

  .home-container {
    padding: 0;
  }

  .main-title {
    margin: 0 32px;
    padding-top: 28px;
  }

  /* ==================== DESKTOP 1025px ABOUT ==================== */

  .about-content-container {
    align-items: center;
    margin: 20px 62px 0 62px;
    text-align: center;
  }

  /* ==================== DESKTOP 1025px EDUCATION ==================== */

  .education-container {
    font-size: 20px;
  }

  /* ==================== DESKTOP 1025px PROJECTS ==================== */

  .projects-content-container {
    margin-top: 40px;
    margin-bottom: 0;
    justify-content: space-evenly;
  }

  /* === PROJECT ITEM CARDS === */
  .project-card-item {
    flex-basis: 47%;
  }

  .project-media-icon {
    font-size: 40px;
    margin: 0 36px 36px 36px;
  }

  .fa-arrow-up-right-from-square {
    padding: 0 5px;
  }

  /* ==================== DESKTOP 1025px CONTACT ==================== */

  .contact-info i {
    font-size: 32px;
  }

  .fa-mobile-screen-button {
    padding: 0 4px; /* center the mobile icon under the envelope icon */
  }

  /* ==================== DESKTOP 1025px FOOTER ==================== */
  #footer {
    font-size: 10px;
    margin-top: 80px;
  }
}
